import React from "react"
import Seo from "../../../components/seo"

import LiquidCooling from "./liquid-cooling"


const LiquidCoolingIndex = () => (

  <>
    <Seo 
      title="Системы охлаждения жидкости. Охлаждение технологических линий."
      description="Системы охлаждения технологических линий и охлаждения жидкостей на промышленных предприятиях."
      keywords="охлаждение линий, охлаждение жидкости, градирни, испарительное охлаждение"
    />
    <LiquidCooling />
  </>
  
)

export default LiquidCoolingIndex